export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは11月より%d点上がりました。日々の心がけの成果ですね。<br>
      慌ただしい12月も良い生活習慣が増やせているようですね。その調子です！
      毎日の生活習慣の積み重ねがご自身の将来の健康につながります。良い習慣は上手く続けてくださいね。
      2023年も＆wellを使いながら、健康的な1年を過ごしましょう。`,
      down: `生活習慣スコアは11月より%d点下がりました。分類別評価で、どの項目に変化があったか見てみましょう。<br>
      良い生活習慣は続けていきましょう。スコアが低い項目は、月ごとに変わりますか？それともいつも同じ項目でしょうか？まずは今の自分の状況を知ることが大切です。
      毎日の生活習慣の積み重ねがご自身の将来の健康につながります。2023年も＆wellを使いながら、健康的な1年を過ごしましょう。`,
      equal100: `生活習慣スコアは11月に引き続き%満点%です。大変素晴らしいです。<br>
      慌ただしい12月も良い生活習慣を続けることができていますね。
      毎日の生活習慣の積み重ねがご自身の将来の健康につながります。良い習慣は上手く続けてくださいね。
      2023年も＆wellを使いながら、健康的な1年を過ごしましょう。`,
      equal: `生活習慣スコアは11月と同じ点数です。<br>
      良い生活習慣は続けていきましょう。スコアが低い項目は、月ごとに変わりますか？それともいつも同じ項目でしょうか？まずは今の自分の状況を知ることが大切です。
      毎日の生活習慣の積み重ねがご自身の将来の健康につながります。2023年も＆wellを使いながら、健康的な1年を過ごしましょう。`,
    },
    steps: {
      up: `歩数スコアは11月より%d点上がりました。<br>
      <b>1月17日</b>からチーム対抗戦が始まります。ケガ予防や歩きすぎて足が痛くならないために、<a href="https://go.andwell.jp/library/7a4425e6-a297-11eb-8810-06d158a16ffc">＜こちらの動画＞</a>を見て、準備をしましょう。
      `,
      down: `歩数スコアは11月より%d点下がりました。<br>
      <b>1月17日</b>からチーム対抗戦が始まります。ケガ予防や歩きすぎて足が痛くならないために、<a href="https://go.andwell.jp/library/7a4425e6-a297-11eb-8810-06d158a16ffc">＜こちらの動画＞</a>を見て、準備をしましょう。
      `,
      equal: `歩数スコアは11月と同じ点数です。<br>
      <b>1月17日</b>からチーム対抗戦が始まります。ケガ予防や歩きすぎて足が痛くならないために、<a href="https://go.andwell.jp/library/7a4425e6-a297-11eb-8810-06d158a16ffc">＜こちらの動画＞</a>を見て、準備をしましょう。
      `,
    },
    exercise: {
      up: `運動のスコアは11月より%d点上がりました。<br>
      夏よりも寒い冬の方が基礎代謝が高く、同じ運動量でも消費カロリーが多くなります。つまり冬の方が、効率よく脂肪を燃焼させることができます！
      `,
      down: `運動のスコアは11月より%d点下がりました。<br>
      夏よりも寒い冬の方が基礎代謝が高く、同じ運動量でも消費カロリーが多くなります。つまり冬の方が、効率よく脂肪を燃焼させることができます！
      `,
      equal: `運動のスコアは11月と同じ点数です。<br>
      夏よりも寒い冬の方が基礎代謝が高く、同じ運動量でも消費カロリーが多くなります。つまり冬の方が、効率よく脂肪を燃焼させることができます！
      `,
    },
    meal: {
      up: `食事のスコアは11月より%d点上がりました。<br>
      忘年会が多い時期も良い食生活ができていますね。今月は新年会があるかもしれませんが、その調子で良い習慣を続けていきましょう。
      `,
      down: `食事のスコアは11月より%d点下がりました。<br>
      食べ過ぎて胃もたれしてしまった時は、食物繊維の多い食品（きのこやこんにゃく、海藻類など）を避けて、胃を休ませてあげてください。
      `,
      equal: `食事のスコアは11月と同じ点数です。<br>
      食べ過ぎて胃もたれしてしまった時は、食物繊維の多い食品（きのこやこんにゃく、海藻類など）を避けて、胃を休ませてあげてください。
      `,
    },
    drinking: {
      up: `飲酒のスコアは11月より%d点上がりました。<br>
      11月よりも飲み過ぎた日が少なくなっています。飲む機会が多い時期に素晴らしいですね。寝起きがスッキリしたなど、何か体の変化はありましたか？
      `,
      down: `飲酒のスコアは11月より%d点下がりました。<br>
      11月よりも飲み過ぎてしまった日が多かったようです。休肝日の目安は週2日以上です。お酒を飲まない曜日を決めるのも1つの方法です。
      `,
      equal: `飲酒のスコアは11月と同じ点数です。<br>
      休肝日の目安は週2日以上です。「週始めの月曜日はお酒を飲まない」など、曜日を決めるのも1つの方法です。
      `,
    },
    sleep: {
      up: `睡眠のスコアは11月より%d点上がりました。<br>
      睡眠は、疲れが取れるだけではなく、免疫力の向上や食欲がコントロールできたり、肌質の改善にもつながります。
      `,
      down: `睡眠のスコアは11月より%d点下がりました。<br>
      睡眠は、疲れが取れるだけではなく、免疫力の向上や食欲がコントロールできたり、肌質の改善にもつながります。
      `,
      equal: `睡眠のスコアは11月と同じ点数です。<br>
      睡眠は、疲れが取れるだけではなく、免疫力の向上や食欲がコントロールできたり、肌質の改善にもつながります。
      `,
    },
    stress: {
      up: `ストレスのスコアは11月より%d点上がりました。<br>
      11月より気分よく過ごせている日が多かったようですね。忙しい時期も上手く気分転換できているようですね。その調子です。
      `,
      down: `ストレスのスコアは11月より%d点下がりました。<br>
      11月より気分が優れない日が多いみたいですね。身体面にストレスサインは出ていませんか？<a href="https://go.andwell.jp/library/c61d8979-c133-11ec-b99b-0af363eb5062">＜こちらのコラム＞</a>を読んで、確認してみてください。
      `,
      equal: `ストレスのスコアは11月と同じ点数です。<br>
      体を動かすと、ネガティブな気分を発散できます。頑張りすぎると疲れてしまうので、これくらいならできそうだと思うものから始めてみましょう。
      `,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score increased by %d points since November. It is the result of your daily efforts.
      It seems that you have added good lifestyle habits even in the busy December. Keep it up! 
Adding your daily lifestyle habits will lead to your own future health. Good habits should continue. 
Spend a healthy year in 2023 with & well.
`,
      down: `Your lifestyle score dropped by %d points since November. Let's take a look at what has changed in the evaluation by classification.
      Continue your good lifestyle. Do items with lower scores change from month to month? Or is it always the same? First of all, it is important to know your current situation. 
Adding your daily lifestyle habits will lead to your own future health. Spend a healthy year in 2023 with & well.`,
      equal100: `Your lifestyle score is perfect as in November. It is very wonderful.
      You have been able to maintain a good lifestyle even in the busy December. 
Adding your daily lifestyle habits will lead to your own future health. Good habits should continue. 
Spend a healthy year in 2023 with & well.`,
      equal: `Your lifestyle score remains the same as in November.
      Continue your good lifestyle. Do items with lower scores change from month to month? Or is it always the same? First of all, it is important for you to know your current situation. 
Adding your daily lifestyle habits will lead to your own future health. Spend a healthy year in 2023 with & well.`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since November.<br>
      The team competition will start <b>on January 17</b>. Watch <a href="https://go.andwell.jp/library/7a4425e6-a297-11eb-8810-06d158a16ffc">this video</a> and get ready to help prevent injury and avoid hurting your feet from walking too long.`,
      down: `Your steps score has gone down %d pts since November. <br>
      The team competition will start <b>on January 17</b>. Watch <a href="https://go.andwell.jp/library/7a4425e6-a297-11eb-8810-06d158a16ffc">this video</a> and get ready to help prevent injury and avoid hurting your feet from walking too long.`,
      equal: `Your steps score is the same as November. <br>
      The team competition will start <b>on January 17</b>. Watch <a href="https://go.andwell.jp/library/7a4425e6-a297-11eb-8810-06d158a16ffc">this video</a> and get ready to help prevent injury and avoid hurting your feet from walking too long.`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since November.<br>
      The basal metabolism becomes higher in winter, as it is colder than in summer, and the same amount of exercise burns more calories. In other words, you can burn fat more efficiently in winter!
      `,
      down: `Your exercise score has gone down %d pts since November.<br>
      The basal metabolism becomes higher in winter, as it is colder than in summer, and the same amount of exercise burns more calories. In other words, you can burn fat more efficiently in winter!
      `,
      equal: `Your exercise score is the same as November.<br>
      The basal metabolism becomes higher in winter, as it is colder than in summer, and the same amount of exercise burns more calories. In other words, you can burn fat more efficiently in winter!
      `,
    },
    meal: {
      up: `Your food score has gone up %d pts since November.<br>
      You have a good diet during a time with many year-end parties. There may be a New Year's party this month, but keep up the good habits.
      `,
      down: `Your food score has gone down %d pts since November.<br>
      If you eat too much and have a stomach ache, avoid foods that are high in fiber (such as mushrooms, konjac, and seaweed) and to give your stomach a rest.
      `,
      equal: `Your food score is the same as November.<br>
      If you eat too much and have a stomach ache, avoid foods that are high in fiber (such as mushrooms, konjac, and seaweed) and to give your stomach a rest.
      `,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since November.<br>
      You have fewer days of drinking too much than in November. That's great when there are many opportunities to drink. Did you experience any physical changes, such as a refreshing wake up?
      `,
      down: `Your alcohol score has gone down %d pts since November.<br>
      You seem to have more days of drinking too much than in November. The guideline is at least two days a week to give a rest to your liver. One idea is to decide which days of the week you do not drink alcohol.
      `,
      equal: `Your alcohol score is the same as November.<br>
      The guideline is at least two days a week to give a rest to your liver. Another way is to decide on the specific day of the week, such as “I don’t drink on Mondays at the beginning the week.”
      `,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since November.<br>
      Sleep not only relieves fatigue, but also improves immunity while controlling appetite, and improving your skin quality.
      `,
      down: `Your sleep score has gone down %d pts since November.<br>
      Sleep not only relieves fatigue, but also improves immunity while controlling appetite, and improving your skin quality.
      `,
      equal: `Your sleep score is the same as November.<br>
      Sleep not only relieves fatigue, but also improves immunity while controlling appetite, and improving your skin quality.
      `,
    },
    stress: {
      up: `Your stress score has gone up %d pts since November.<br>
      You seem to have more days feeling better than in November. You seem to change your mood well even during busy periods. Keep it up.
      `,
      down: `Your stress score has gone down %d pts since November.<br>
      You seem to have more day not feeling well than in November. Are there any physical signs of stress? Read <a href="https://go.andwell.jp/library/c61d8979-c133-11ec-b99b-0af363eb5062">this column</a> to find out.
      `,
      equal: `Your stress score is the same as November.<br>
      Move your body to improve a negative mood. Too much effort may exhaust you, why don't you start with something you fee you can do?
      `,
    },
  },
}
